import { dateToApiFormat } from '@/helpers/dateHelper';
import { useGetMeQuery, useGetShiftOperatingHoursQuery } from '@/services/gql/graphql.generated';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useCallback } from 'react';

export const useGetMinMaxTimes = (dates: Date[]) => {
  const defaultMaxTime = useSystemSettings(state => state.settings.maxTime);
  const defaultMinTime = useSystemSettings(state => state.settings.minTime);

  const [{ data: myData }] = useGetMeQuery();
  const locationId = myData?.myEmployee?.homeLocationId;

  if (locationId === undefined) {
    throw Error('Location ID must be set');
  }

  const [{ data: timesData }] = useGetShiftOperatingHoursQuery({
    variables: {
      locationId,
      start: dateToApiFormat(dates[0]),
      end: dateToApiFormat(dates[dates.length - 1])
    }
  });

  const times = timesData?.shiftOperatingHoursInDateRange;

  return useCallback(
    (date: Date) => {
      const dataForDate = times?.find(t => t.date === dateToApiFormat(date));
      const minTime = dataForDate?.startTime || defaultMinTime;
      const maxTime = dataForDate?.endTime || defaultMaxTime;

      return { minTime, maxTime };
    },
    [times, defaultMaxTime, defaultMinTime]
  );
};
