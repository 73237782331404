import { EmployeeSchedule } from '../schedule/schedule.types';
import { useValidateScheduleAvailabilityDuration } from './useValidateScheduleAvailabilityDuration';
import { useValidateScheduleLeave } from './useValidateScheduleLeave';
import { useValidateScheduleOutsidePattern } from './useValidateScheduleOutsidePattern';

export const useValidateAvailabilities = (days: Date[], schedule: EmployeeSchedule) => {
  const leaveErrors = useValidateScheduleLeave(days, schedule);
  const durationErrors = useValidateScheduleAvailabilityDuration(schedule, days[0], days[days.length - 1]);
  const patternErrors = useValidateScheduleOutsidePattern(schedule, days[0], days[days.length - 1]);

  return [...leaveErrors, ...durationErrors, ...patternErrors];
};
