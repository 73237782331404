import { dateToCalendarStateIndex } from '@/helpers/calendarHelper';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import {
  AvailabilityException,
  WorkingPatternDay,
  useEmployeeWorkingPatternQuery,
  useMyAvailabilityExceptionsQuery
} from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { useCallback, useEffect, useState } from 'react';
import { useBuildSchedule } from './useBuildSchedule';
import { EmployeeSchedule, EmployeeScheduleDay } from './schedule.types';

export const useEmployeeSchedule = (selectedDate: Date) => {
  const [{ data: myAvailabilityData }, refetchExceptions] = useMyAvailabilityExceptionsQuery({
    requestPolicy: 'network-only'
  });
  const exceptions = myAvailabilityData?.myAvailabilityExceptions?.exceptions as AvailabilityException[];

  const [{ data: myWorkingPatternData }, refetchPatterns] = useEmployeeWorkingPatternQuery({
    requestPolicy: 'network-only'
  });

  if (
    !myWorkingPatternData?.employeeWorkingPatterns?.periods ||
    myWorkingPatternData?.employeeWorkingPatterns?.periods.length === 0
  ) {
    throw Error('Employee working pattern data should be setup');
  }

  const patterns = myWorkingPatternData?.employeeWorkingPatterns?.periods[0].patterns as WorkingPatternDay[];
  if (!patterns) {
    throw Error('Patterns should be defined.');
  }

  const [fetching, setFetching] = useState<boolean>(false);
  const refetch = useCallback(async () => {
    setFetching(true);
    await refetchExceptions({ requestPolicy: 'network-only' });
    await refetchPatterns({ requestPolicy: 'network-only' });
    setFetching(false);
  }, [refetchExceptions, refetchPatterns]);

  const buildSchedule = useBuildSchedule(selectedDate);
  
  const [schedule, setSchedule] = useState<EmployeeSchedule>(() => buildSchedule(patterns, exceptions));

  useEffect(() => {
    setSchedule(buildSchedule(patterns, exceptions));
  }, [exceptions, patterns, buildSchedule]);

  const { registerConfirmation } = useConfirm(state => ({ registerConfirmation: state.registerForConfirmation }));

  const setScheduleDay = useCallback(
    (scheduleDay: EmployeeScheduleDay) => {
      const newSchedule = { ...schedule };
      newSchedule[dateToCalendarStateIndex(scheduleDay.date)] = scheduleDay;
      setSchedule(newSchedule);
      registerConfirmation(strings.ess.availability.saveConfirm);
    },
    [schedule, setSchedule]
  );

  return { schedule, setScheduleDay, refetch, fetching };
};
