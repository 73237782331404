import { secondsToTimeString } from '@/helpers/dateHelper';
import { FC, useMemo } from 'react';
import { SelectInput } from './SelectInput';

interface Props {
  value: number;
  setValue: (newValue: number) => void;
  className?: string;
  incrementSeconds: number;
  maxTime: number;
  minTime: number;
  readonly?: boolean;
}

export const TimeStepInput: FC<Props> = ({
  value,
  setValue,
  className,
  incrementSeconds = 15,
  maxTime = 24 * 60 * 60,
  minTime = 0,
  readonly
}) => {
  const options = useMemo(() => {
    const times: number[] = [];

    let timeToAdd = minTime;
    while (timeToAdd <= maxTime) {
      times.push(timeToAdd);
      timeToAdd += incrementSeconds;
    }

    if (!times.find(t => t === value)) {
      times.push(value);
    }

    times.sort((a, b) => a - b);

    return times.map(time => ({ name: secondsToTimeString(time), value: time }));
  }, [incrementSeconds, maxTime, minTime, value]);

  return (
    <SelectInput
      options={options}
      value={value}
      setValue={newVal => setValue(parseInt(newVal))}
      className={className}
      readOnly={readonly}
      defaultValue={secondsToTimeString(0)}
    />
  );
};
