import { dateToCalendarStateIndex } from '@/helpers/calendarHelper';
import { getCalendarEndDate, getCalendarStartDate } from '@/helpers/dateHelper';
import { EmployeeSchedule, EmployeeScheduleDay } from '@/services/ess/schedule/schedule.types';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useNextAvailabilityPeriod } from '@/services/settings/useDates';
import { addMonths, startOfToday, subYears } from 'date-fns';
import { FC } from 'react';
import { Returns } from 'use-lilius';
import { EssCalendarHeader } from '../calendar/EssCalendarHeader';
import { EssWorkPreferenceDay } from './EssWorkPreferenceDay';
import { useGetMinMaxTimes } from './useMinMaxTimes';

interface Props {
  schedule: EmployeeSchedule;
  setScheduleDay: (scheduleDay: EmployeeScheduleDay) => void;
  liliusProps: Returns;
  daysToShow: Date[];
}

export const EssWorkPreference: FC<Props> = ({ schedule, setScheduleDay, liliusProps, daysToShow }) => {
  /*  If we get the week days names from the settings we can use it like this:

      const weekDayName: any = 'monday';
      const weekStartsOn = Day[weekDayName.toUpperCase()] as any;
  */
  const requestedStartMonthDate = 1;

  const optionalDeadline = useSystemSettings(state => state.settings.availabilityDeadlineSettings.sendReminders);

  const { nextPeriodStart } = useNextAvailabilityPeriod();

  const { viewing, viewNextMonth, viewPreviousMonth } = liliusProps;

  const futureAvailabilityMonths = useSystemSettings(state => state.settings.futureAvailabilityMonths);

  const today = startOfToday();
  const minAllowedDate = subYears(today, 3);
  const maxAllowedDate = addMonths(today, futureAvailabilityMonths);
  const startCalendarDate = getCalendarStartDate(viewing, requestedStartMonthDate);
  const endCalendarDate = getCalendarEndDate(startCalendarDate, requestedStartMonthDate);

  const getMinMax = useGetMinMaxTimes(daysToShow);

  return (
    <div className="w-full mt-2 py-2 border border-x-0 border-gray-300 bg-white shadow-md">
      <EssCalendarHeader
        id={'availability'}
        viewPreviousMonth={viewPreviousMonth}
        viewNextMonth={viewNextMonth}
        startDate={startCalendarDate}
        endDate={endCalendarDate}
        viewing={viewing}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
      />
      <div className="overflow-y-auto max-h-[55vh]">
        {daysToShow.map(day => {
          const isPastDate = day < today;
          const editable = !isPastDate && (optionalDeadline || day >= nextPeriodStart);

          const scheduleDay = schedule[dateToCalendarStateIndex(day)] || {
            date: day
          };

          const { minTime, maxTime } = getMinMax(day);

          return (
            <EssWorkPreferenceDay
              key={day.toString()}
              scheduleDay={scheduleDay}
              onChange={editable ? setScheduleDay : undefined}
              minTime={minTime}
              maxTime={maxTime}
            />
          );
        })}
      </div>
    </div>
  );
};
