import { dateToCalendarStateIndex } from '@/helpers/calendarHelper';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { LeaveType } from '@/services/leaveManagement/leave.types';
import { useEssLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { strings } from '@/services/translation/strings';
import { useMemo } from 'react';
import { AvailabilityError, AvailabilityErrorLevel } from '@/pages/ess/EssAvailabilityError';
import { EmployeeSchedule } from '../schedule/schedule.types';

export enum LeaveErrorReason {
  BELOW_MINIMUM,
  ABOVE_MAXIMUM
}
export interface LeaveError {
  type: LeaveType;
  quantity: number;
  reason?: LeaveErrorReason;
  level?: AvailabilityErrorLevel;
}

export const useValidateScheduleLeave = (days: Date[], schedule: EmployeeSchedule) => {
  const leaveTypes = useEssLeaveTypes();

  return useMemo(() => {
    const leaveErrors: LeaveError[] = leaveTypes
      .filter(lt => lt.active && lt.id !== FixedLeaveTypeId.ROSTER)
      .map(lt => ({ type: lt, quantity: 0 }));

    leaveErrors.forEach(leaveError => {
      const leaveType = leaveError.type;
      const count = days.reduce((runningTotal, day) => {
        const scheduleDay = schedule[dateToCalendarStateIndex(day)];

        if (scheduleDay && scheduleDay.leaveType === leaveType.id) {
          return runningTotal + 1;
        }

        return runningTotal;
      }, 0);

      leaveError.quantity = count;

      if (leaveType.limitSettings.min.value !== 0 && count < leaveType.limitSettings.min.value) {
        leaveError.level = leaveType.limitSettings.min.mandatory
          ? AvailabilityErrorLevel.ERROR
          : AvailabilityErrorLevel.WARNING;
        leaveError.reason = LeaveErrorReason.BELOW_MINIMUM;
      }

      if (leaveType.limitSettings.max.value !== 0 && count > leaveType.limitSettings.max.value) {
        leaveError.level = leaveType.limitSettings.max.mandatory
          ? AvailabilityErrorLevel.ERROR
          : AvailabilityErrorLevel.WARNING;
        leaveError.reason = LeaveErrorReason.ABOVE_MAXIMUM;
      }
    });

    const filtered = leaveErrors.filter(le => le.level !== undefined && le.reason !== undefined);
    const sorted = filtered.sort((a, b) => b.level! - a.level!);

    return sorted.map(error => {
      let text = '';
      const name = error.type.properties.displayName ? error.type.properties.displayName : error.type.name;

      if (error.reason === LeaveErrorReason.BELOW_MINIMUM) {
        if (error.level === AvailabilityErrorLevel.ERROR) {
          text = strings.ess.availability.warningModal.notMetMandatory(
            error.type.limitSettings.min.value,
            error.quantity,
            name
          );
        } else {
          text = strings.ess.availability.warningModal.notMetWarning(
            error.type.limitSettings.min.value,
            error.quantity,
            name
          );
        }
      } else if (error.reason === LeaveErrorReason.ABOVE_MAXIMUM) {
        if (error.level === AvailabilityErrorLevel.ERROR) {
          text = strings.ess.availability.warningModal.exceededMandatory(
            error.type.limitSettings.max.value,
            error.quantity,
            name
          );
        } else {
          text = strings.ess.availability.warningModal.exceededWarning(
            error.type.limitSettings.max.value,
            error.quantity,
            name
          );
        }
      }

      return {
        text,
        level: error.level
      } as AvailabilityError;
    });
  }, [leaveTypes, days, schedule]);
};
