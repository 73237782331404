import { Button } from '@/components/button';
import { RefreshButton } from '@/components/buttons/RefreshButton';
import { EssWorkPreference } from '@/components/workPreference/EssWorkPreference';
import { getDaysInMonth } from '@/helpers/dateHelper';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { strings } from '@/services/translation/strings';
import { FC, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { FiCheck, FiX } from 'react-icons/fi';
import { MdPerson } from 'react-icons/md';
import { Day, useLilius } from 'use-lilius';
import { useAvailabilityKey } from '../../services/ess/availability/useAvailabilityKey';
import { useEmployeeSchedule } from '../../services/ess/schedule/useEmployeeSchedule';
import { ESSPageHeader } from '../layout/ess/ESSPageHeader';
import { EssAvailabilityError } from './EssAvailabilityError';
import { EssAvailabilityWarningModal } from './EssAvailabilityWarningModal';
import { useSaveEmployeeSchedule } from '@/services/ess/schedule/useSaveEmployeeSchedule';
import { useValidateAvailabilities } from '@/services/ess/availability/useValidateAvailabilities';

export const EssAvailability: FC = () => {
  const requestedStartMonthDate = 1;

  const getStartDate = () => {
    const viewing = new Date();
    viewing.setDate(requestedStartMonthDate);
    viewing.setMonth(viewing.getMonth() + 1);

    return viewing;
  };

  const liliusProps = useLilius({
    viewing: getStartDate(),
    weekStartsOn: Day.MONDAY
  });

  const { viewing } = liliusProps;
  const daysToShow = useMemo(
    () => getDaysInMonth(viewing, requestedStartMonthDate),
    [viewing, requestedStartMonthDate]
  );

  const { schedule, setScheduleDay, refetch, fetching: refreshFetching } = useEmployeeSchedule(liliusProps.viewing);

  const { save, fetching: saveFetching } = useSaveEmployeeSchedule(daysToShow[0], daysToShow[daysToShow.length - 1]);

  const { saveNeeded } = useConfirm(state => ({ saveNeeded: state.confirmationNeeded }));

  const availabilityKey = useAvailabilityKey();

  const [confirmSaveOpen, setConfirmSaveOpen] = useState<boolean>(false);

  const errors = useValidateAvailabilities(daysToShow, schedule);
  const hasError = errors.length > 0;

  const onClose = () => setConfirmSaveOpen(false);

  const onSave = async () => {
    const result = await save(schedule);

    if (result.error) {
      toast(strings.common.error, {
        position: 'top-center',
        className: 'text-sm',
        icon: <FiX className="h-4 w-4" />
      });
    } else {
      toast(strings.common.success, {
        position: 'top-center',
        className: 'text-sm',
        icon: <FiCheck className="h-4 w-4" />
      });
    }

    onClose();
  };

  return (
    <>
      <EssAvailabilityWarningModal
        onSave={onSave}
        errors={errors}
        open={confirmSaveOpen}
        onClose={onClose}
        fetching={saveFetching}
      />
      <ESSPageHeader title={strings.ess?.availability.title}>
        <div className="flex items-center gap-4">
          <RefreshButton fetching={refreshFetching} onRefresh={refetch} />
          <Button
            className="w-24"
            size="sm"
            loading={!confirmSaveOpen && saveFetching}
            variant={saveNeeded ? 'submit' : undefined}
            onClick={hasError ? () => setConfirmSaveOpen(true) : onSave}
            disabled={saveNeeded ? false : true}
          >
            {strings.ess.availability.submitAvailability}
          </Button>
        </div>
      </ESSPageHeader>
      <div className="flex flex-col items-center py-2 px-5">
        <div className="flex flex-row gap-4">
          {availabilityKey.map(type => {
            return (
              <div className="flex flex-col" key={type.name}>
                <div className="flex flex-row items-center gap-1">
                  <span
                    style={{ backgroundColor: type.color }}
                    className={`rounded-full w-3 h-3 inline-block items-center`}
                  ></span>
                  <div className="text-sm">{type.name}</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <span className="rounded-full w-3 h-3 inline-block items-center"></span>
                  <div className="text-xs">{type.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col items-start py-2 px-5">
        <div className="flex flex-row items-center space-x-2">
          <MdPerson className="text-primary" />
          <span className="text-sm">{strings.ess?.availability.totalEmployees}</span>
        </div>
      </div>
      <EssWorkPreference
        schedule={schedule}
        setScheduleDay={setScheduleDay}
        liliusProps={liliusProps}
        daysToShow={daysToShow}
      />
      {hasError && (
        <div className="flex flex-col items-start py-3 px-5 space-y-2 max-h-[10vh] overflow-auto my-5 border rounded-md">
          {errors.map((e, index) => (
            <EssAvailabilityError key={`availabilityError-${index}`} error={e} />
          ))}
        </div>
      )}
    </>
  );
};
