import { dateToCalendarStateIndex, getTranslatedDayOfWeek } from '@/helpers/calendarHelper';
import {
  dateToApiFormat,
  dateToTimeString,
  getCalendarEndDate,
  getCalendarStartDate,
  secondsToTimeString
} from '@/helpers/dateHelper';
import { EmployeeSchedule } from '@/services/ess/schedule/schedule.types';
import { useLastModified } from '@/services/ess/useLastModified';
import { Location, Shift, useGetMeQuery } from '@/services/gql/graphql.generated';
import { DayOfWeekAndHoliday } from '@/services/holiday/holiday.types';
import {
  useGetDayOfWeekAndHoliday,
  useGetPublicHoliday
} from '@/services/holiday/usePublicHolidays';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { addDays, addMonths, differenceInDays, format, isToday, startOfToday, subMonths } from 'date-fns';
import React, { FC, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Divider from '../Divider';
import { EssCalendarHeader } from '../calendar/EssCalendarHeader';
import { EssReadAvailability } from './EssReadAvailability';

interface Props {
  schedule: EmployeeSchedule;
  shifts: Shift[];
  onNextMonth: () => void;
  onPrevMonth: () => void;
  viewing: Date;
}

export const EssMonthlyShift: FC<Props> = ({ schedule, onNextMonth, onPrevMonth, viewing, shifts }) => {
  const [{ data: myProfileData }] = useGetMeQuery();
  const homeLocationId = myProfileData?.myEmployee?.homeLocationId;

  const latestPublishedDate = useLastModified(shifts);

  const requestedStartMonthDate: number = useSystemSettings(state => state.settings.monthStartDay);
  const requestedDaysFormat: 'full' | 'mid' | 'basic' = 'basic';
  const startCalendarDate = getCalendarStartDate(viewing, requestedStartMonthDate);
  const endCalendarDate = getCalendarEndDate(startCalendarDate, requestedStartMonthDate);

  const getHoliday = useGetPublicHoliday(startCalendarDate);
  const getDayOfWeekAndHoliday = useGetDayOfWeekAndHoliday(startCalendarDate);

  const dayRows = [];

  const dateDiff = differenceInDays(endCalendarDate, startCalendarDate);

  const currentDayRef = useRef(null);
  const calendarRef = useRef(null);

  useEffect(() => {
    if (currentDayRef.current) {
      //@ts-ignore
      currentDayRef.current?.scrollIntoView();
    } else {
      //@ts-ignore
      calendarRef.current.scrollTo(0, 0);
    }
  }, [currentDayRef, calendarRef, viewing]);

  for (let day = 0; day <= dateDiff; day++) {
    const currentDate = addDays(startCalendarDate, day);
    const currentDateNumber = currentDate.getDate();
    const dayOfWeekAndHoliday = getDayOfWeekAndHoliday(currentDate);
    const dateIndex = dateToCalendarStateIndex(currentDate);
    const shiftsForDay = shifts.filter(shift => dateToApiFormat(new Date(shift.date)) === dateToApiFormat(currentDate));
    shiftsForDay.sort((a, b) => a.start - b.start);

    const locations = shiftsForDay.reduce((prevArray: Location[], shift) => {
      if (!shift.location) {
        return prevArray;
      }

      if (prevArray.find(l => l.id === shift.location?.id)) {
        return prevArray;
      }

      const newArray = [...prevArray];
      newArray.push(shift.location);

      return newArray;
    }, []);

    const isPastDate = currentDate < startOfToday();

    const holiday = getHoliday(currentDate);

    dayRows.push(
      <div
        className={`flex flex-row items-center justify-between py-3 ${
          isPastDate ? 'bg-gray-100 text-calendar-inactive-default' : ''
        }
          ${isToday(currentDate) ? 'bg-[#FCFCC9]' : ''}`}
        ref={isToday(currentDate) ? currentDayRef : undefined}
      >
        <div className="flex flex-col">
          <div
            className={` flex flex-row gap-2 text-sm font-semibold  ${
              dayOfWeekAndHoliday === DayOfWeekAndHoliday.SUNDAY || dayOfWeekAndHoliday === DayOfWeekAndHoliday.HOLIDAY
                ? isPastDate
                  ? 'text-calendar-inactive-sunday'
                  : 'text-calendar-active-sunday'
                : dayOfWeekAndHoliday === DayOfWeekAndHoliday.SATURDAY
                ? isPastDate
                  ? 'text-calendar-inactive-saturday'
                  : 'text-calendar-active-saturday'
                : ''
            }`}
          >
            <div className="w-5 text-right pl-2 ">{currentDateNumber}</div>
            <div className="w-5 text-right">
              {getTranslatedDayOfWeek(requestedDaysFormat, currentDate.getDay() as DayOfWeekAndHoliday)}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col px-2 sm:px-5">
          {holiday && <div>{holiday.name}</div>}
          {locations.map(location => (
            <div
              key={'shift-location-' + location.id}
              className={twMerge(
                'flex rounded-lg w-full p-3 h-6 gap-1 items-center',
                !homeLocationId || homeLocationId === location.locationNumber ? 'bg-transparent' : 'bg-orange-400'
              )}
            >
              {location.name} {location.locationNumber}
            </div>
          ))}
          {!shiftsForDay.length && (
            <EssReadAvailability
              leaveTypeId={schedule[dateIndex]?.leaveType}
              transparencyOptions={{ shouldUseTransparency: isPastDate }}
            />
          )}
        </div>
        <div className="flex flex-row">
          <div className="w-32 text-right pr-2">
            {shiftsForDay.length ? (
              shiftsForDay.map((shift: any) => (
                <div key={'shift-range-' + shift.id}>
                  {secondsToTimeString(shift.start)} - {secondsToTimeString(shift.end)}
                </div>
              ))
            ) : (
              <div className="flex justify-end gap-[0.65rem]">
                <span>--:--</span> - <span>--:--</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const today = new Date();
  const minAllowedDate = subMonths(today, 0);
  const maxAllowedDate = addMonths(today, 4);

  return (
    <div className="w-full">
      <div className="relative">
        <div className="absolute top-0 left-0 w-full mt-2 py-2 border border-x-0 border-gray-300 bg-white shadow-md">
          <EssCalendarHeader
            id={'monthlyShift'}
            viewPreviousMonth={onPrevMonth}
            viewNextMonth={onNextMonth}
            startDate={startCalendarDate}
            endDate={endCalendarDate}
            viewing={viewing}
            minAllowedDate={minAllowedDate}
            maxAllowedDate={maxAllowedDate}
          />
          <div className="flex flex-col overflow-y-auto h-96 shifts-publishing" ref={calendarRef}>
            {shifts.length === 0 ? (
              <p className="flex justify-end text-sm pr-2 pb-2">{strings.ess.monthlyShift.noShifts}</p>
            ) : (
              <p className="flex justify-end text-sm pr-2 pb-2 published-date">
                {latestPublishedDate &&
                  strings.ess.monthlyShift.published(
                    format(latestPublishedDate, 'yyyy/MM/dd'),
                    dateToTimeString(latestPublishedDate)
                  )}
              </p>
            )}
            {dayRows.map((r, index) => (
              <React.Fragment key={index}>
                <Divider style={{ margin: 0 }} />
                {r}
              </React.Fragment>
            ))}
            <Divider style={{ marginTop: 0 }} />
          </div>
        </div>
      </div>
    </div>
  );
};
